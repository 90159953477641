import Vue from "vue";
const url = {
  
  登录: "/es/user/login",
  退出登录:"/es/user/logout",
  修改密码: "/es/user/modify/my/pwd",
  获取菜单:"/es/user/query/my/menu",

  //公司管理
  添加公司:"/es/company/add",
  编辑公司:"/es/company/edit",
  公司列表:"/es/company/list",
  添加考场:"/es/exam/spot/add",
  编辑考场:"/es/exam/spot/edit",
  考场列表:"/es/exam/spot/list",
  收费权限:"/es/company/set/open/exam/spot",
  班车权限:"/es/company/set/open/exam/bus",
  承星日志:"/es/history/list/by/cx",
  //用户管理
  添加客户用户:"/es/user/add",
  用户列表:"/es/user/list/by/company",
  用户删除:"/es/user/remove",
  班车用户列表:"/es/user/list/by/exam/spot/bus",
  管理员添加:"/es/user/cx/add",
  管理员编辑:"/es/user/cx/edit",
  删除管理员:"/es/user/cx/remove",
  用户编辑:"/es/user/edit",
  //角色管理
  角色列表:"/es/role/list",
  角色编辑:"/es/role/edit",
  角色添加:"/es/role/add",
  删除角色:"/es/role/remove",
  角色菜单:"/es/role/query/one",
  设置菜单:"/es/menu/access/save/one/role/customize",
  //班车相关
  获取班车有效期:"/es/exam/spot/bus/list",
  设置班车有效期:"/es/exam/spot/bus/set/expire",
  管理参数:"/es/setting/list",
  参数设置:"/es/setting/save",
  班车操作日志:"/es/history/list/by/exam/spot/bus",
  //班车-车辆管理
  班车车辆列表:"/es/bus/list",
  班车车辆添加:"/es/bus/add",
  班车车辆编辑:"/es/bus/edit",

  线路列表:"/es/exam/spot/bus/line/list",
  添加线路:"/es/exam/spot/bus/line/add",
  线路编辑:"/es/exam/spot/bus/line/edit",
  删除线路:"/es/exam/spot/bus/line/remove",
  线路状态:"/es/exam/spot/bus/line/open",

  站点列表:"/es/exam/spot/bus/depot/list",
  添加站点:"/es/exam/spot/bus/depot/add",
  编辑站点:"/es/exam/spot/bus/depot/edit",
  删除站点:"/es/exam/spot/bus/depot/remove/one",

  班车约车人数显示:"/es/exam/spot/bus/plan/list",
  线路派车:"/es/exam/spot/bus/plan/add",
  取消派车:"/es/exam/spot/bus/plan/cancel",
  预约学员信息:"/es/exam/spot/bus/plan/depot/list/passengers",
  下载二维码:"/es/exam/spot/bus/redirect/qrcode/query",
  上车二维码:"/es/exam/spot/bus/unify/qrcode/query",

  // 考场相关
  用户所有考场:"/es/user/exam/spot/list",
  考场用户列表:"/es/user/list/by/exam/spot",
  考场操作日志:"/es/history/list/by/exam/spot",
  强训套餐列表:"/es/hot/meal/list",
  添加套餐:"/es/hot/meal/add",
  编辑套餐:"/es/hot/meal/edit",
  删除套餐:"/es/hot/meal/remove",
  考车列表:"/es/car/list",
  添加考车:"/es/car/add",
  编辑考车: "/es/car/status",
  考车编辑:"/es/car/edit",


  业务员列表:"/es/hot/agent/list",
  添加业务员:"/es/hot/agent/add",
  编辑业务员:"/es/hot/agent/edit",
  删除业务员:"/es/hot/agent/remove",

  票据信息:"/es/exam/spot/ext/query",
  设置票据信息:"/es/exam/spot/ext/set",

  成绩导入模板:"/es/exam/score/excel/title/list",
  成绩导入模板设置:"/es/exam/score/excel/title/save",

  强训学员:"/es/hot/fee/list",
  添加强训学员:"/es/hot/fee/add",
  取消强训:"/es/hot/fee/cancel",
  强训记录查询:"/es/hot/fee/student/train/history/list",
  查询考车:"/es/car/list/order",
  导出强训学员:"/es/hot/fee/file/dld",

  预约学员列表:"/es/exam/reserve/list",
  导入预约学员:"/es/exam/reserve/file/import",
  预约学员导入模板:"/es/exam/reserve/excel/title/list",
  导出预约学员:"/es/exam/reserve/file/dld",
  设置预约导入模板:"/es/exam/reserve/excel/title/save",
  
  练车名单:"/es/hot/fee/car/stat",
  导出练车名单:"/es/hot/fee/car/stat/dld",
  成绩录入:"/es/hot/fee/set/exam/pass",
  成绩导入:"/es/hot/fee/score/file/import",

  合格率统计:"/es/hot/fee/stat/by/fee",
  业务员合格率统计:"/es/hot/fee/stat/by/agent",
  业务员套餐合格率:"/es/hot/fee/stat/by/meal",
  合格率的学员:"/es/hot/fee/stat/student/page",

  导出是否收费:"/es/hot/fee/stat/by/fee/dld",
  导出业务合格率:"/es/hot/fee/stat/by/agent/dld",
  导出套餐合格率:"/es/hot/fee/stat/by/meal/dld",

  添加预约须知:"/es/company/bus/instructions/save",
  获取预约须知:"/es/company/bus/instructions/query"




};

Vue.prototype.$url = url;
