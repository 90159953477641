<template>
  <div @click="clearAction()" style="height:100%;position:relative;z-index:9">
    <a-layout style="min-height:100%;">
      <!-- 主色 0960ba -->
      <a-layout-header :style="{ background: '#1890ff', padding: 0,position:'fixed',width:'100%',height:'50px',zIndex:'1000' }" >
        <div class="folder">
          <a-icon
            style="color:#fff;"
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
        </div>
        <Header></Header>  
      </a-layout-header>
      <a-layout id="components-layout-demo-fixed-sider" >
        <a-layout-sider v-model="collapsed" :style="{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0 ,top:'50px',}" :trigger="null" collapsible>
          <side-menu></side-menu>
        </a-layout-sider>
        <a-layout :style="{ marginLeft: collapsed?'80px':'200px',marginTop: '50px',position:'relative' }" >
          <a-layout-content class="main-container" :style="{ margin: '10px 16px 0', overflow: 'initial',}" :key="curTimer"  >
            <div class="tabbar">
              <span class="tab-item" v-for="(pane,index) in tab" :key="pane.key" @mousedown="downAct(index,$event)">
                <router-link :to="pane.key" :class="{activeClass:$route.path.indexOf(pane.key)!=-1}"> {{pane.title}} </router-link>
                <a-icon type="close" class="close-tab" @click="closeCurTab(index)" v-if="tab.length == 1 ? false:true" />
                <!-- <router-link tag="button" @mousedown.prevent="downAct(index,$event)">{{pane}}</router-link> -->
              </span>
            </div>
            <div class="nav-action" ref="nav">
              <div @click="closeLeft"><a-icon type="arrow-left" />关闭左侧</div>
              <div @click="closeRight"><a-icon type="arrow-right" />关闭右侧</div>
              <div @click="closeElse"><a-icon type="close" />关闭其他</div>
            </div>
             
            <router-view class="content-con" :key="$store.state.load" v-if="$store.state.load" />
            
          </a-layout-content>
          <!-- 底部公司信息 -->
          <div class="footer-info">
              本产品由 . 陕西旭梦承星科技有限公司开发 <span style="margin:0 20px;">©2021 cxkj</span>
              <a href="https://www.xmcxkj.net/" target="_black">公司官网:https://www.xmcxkj.net/</a>
          </div>
        </a-layout>
      </a-layout>
    </a-layout>
  </div>
</template>
<script>
  import Header from "./Header"
  import sideMenu from "./sideMenu"
  export default {
    components:{
      Header,
      sideMenu,
    },
    data() {
      return {
        tab:[ ],
        collapsed: false,
        activeKey: '/carschool',
        newTabIndex: 0,
        splitIndex:0,
        curTimer:new Date().getTime(),
        curRoute:'',
        companyId:sessionStorage.getItem('companyId')
      };
    },
    methods: {
      callback(key) {
        console.log(key);
      },
      onEdit(targetKey, action) {
        this[action](targetKey);
      },
      remove(targetKey) {
        let activeKey = this.activeKey;
        this.tab.forEach((ta, i) => {
          if (ta.key === targetKey) {
            this.tab.splice(i,1);
          }
        });
        let lastIndex;
        this.tab.forEach((pane, i) => {
          if (pane.key === targetKey) {
            lastIndex = i - 1;
          }
        });
        const panes = this.tab.filter(pane => pane.key !== targetKey);
        if (panes.length && activeKey === targetKey) {
          if (lastIndex >= 0) {
            activeKey = panes[lastIndex].key;
          } else {
            activeKey = panes[0].key;
          }
        }
        this.tab = panes;
        this.activeKey = activeKey;
        this.$forceUpdate()
      },
      changeTab(activeKey){
        this.$router.push({ path:activeKey })
      },
      closeLeft(){
        let arr = []
        this.tab.forEach((item,index)=>{
          if(index >= this.splitIndex)arr.push(item)
        })
        this.tab = arr
        this.activeKey = arr[0].key 
        this.$router.push({ path:arr[0].key })
        this.curTimer = new Date().getTime()
      },
      closeRight(){
        let arr = []
        this.tab.forEach((item,index)=>{
          if(index <= this.splitIndex)arr.push(item)
        })
        this.tab = arr
        this.activeKey = arr[arr.length-1].key 
        this.$router.push({ path:arr[arr.length-1].key })
        this.curTimer = new Date().getTime()
      },
      closeElse(){
        let arr = []
        this.tab.forEach((item,index)=>{
          if(index == this.splitIndex)arr.push(item)
        })
        this.tab = arr
        this.activeKey = arr[0].key 
        this.$router.push({ path:arr[0].key })
        this.curTimer = new Date().getTime()
      },
      closeCurTab(i){
        if(this.tab[i].key == this.$route.path){
          this.tab.splice(i,1)
          this.$router.push({ path:this.tab[this.tab.length-1].key })
        }else{
          this.tab.splice(i,1)
        }
      },
      clearAction(){
        let navA = this.$refs.nav
        navA.style.display = "none"
        document.oncontextmenu = null
      },
      downAct(i,e){
        this.splitIndex = i;
        let navA = this.$refs.nav
        navA.style.display = "none"
        document.oncontextmenu=function(ev){
          navA.style.display = "block"
          navA.style.left = (e.x+10)+"px"
          navA.style.top = (e.y-e.offsetY+10)+"px"
          return false;    //屏蔽右键菜单
        }
      }
    },
    watch: {
      $route: {
        handler: function(val, oldVal){
          let isEsit = false;
          this.tab.forEach(item=>{
            if(item.key == val.path){
              isEsit = true
            }
          })
          if(!isEsit){
            this.tab.push({
              title: val.name, 
              content: val.path,
              key: val.path
            })
          }
          this.activeKey = val.path
        },
        // 深度观察监听
        deep: true
      }
    },
    mounted(){
      if(this.companyId==100){
        this.$store.commit('updateLoad',new Date().getTime())
      }
      if(this.$route.fullPath =='/body')return
      this.tab.push({
        "title": this.$route.name,
         "content": this.$route.fullPath, 
         "key": this.$route.fullPath
      })
      //f5刷新时执行
      window.addEventListener('beforeunload', e => {
        this.$store.commit('updateLoad','')
        this.tab = []
      })
    }
  };
</script>
<style>
.footer-info{
  text-align:center;
  color:#aaa;
  line-height:40px;
}
.tabbar{
  border-bottom:solid 1px #e8e8e8;
  margin-bottom:15px
}
.nav-action{
  position:fixed;
  z-index:100;
  background:#fff;
  box-shadow: 0  0px 10px rgba(0,0,0,0.1);
  border-radius:2px;
  display:none;
  cursor:pointer;
}
.nav-action div{
  padding:10px 18px 8px;
  border-bottom:dashed 1px rgba(0,0,0,0.2)
  }
.nav-action div:hover{
  color:rgb(9, 96, 186)
  }
.nav-action .anticon{
  margin-right:8px;
  }
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.trigger{
  font-size:30px;
  color:#fff;
}
.folder{
  background:#156cbc;
  width:60px;
  height:50px;
  padding-left:13px;
  float:left;
}
.tab-item{
  background:#fff;
  padding:8px 12px;
  display: inline-block;
  margin:0 2px 0 0;
}
.tab-item a{
  color:rgba(0,0,0,0.5)
}
.tab-item .activeClass{
  color:rgb(24, 144, 255)
}
.close-tab{
  cursor: pointer;
  opacity:0.8
}
.main-container{
  position:relative;
  overflow:scroll;
  padding-top:50px;
  
}
.tabbar{
  top:0;
  width:100%;
  position: absolute;
  left:0;
}
.content-con{
  flex:1
}
</style>