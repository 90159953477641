<template>
    <div>
        <div class="logo">
            <img src="../src/assets/logo.svg" alt="" class="logo-pic">
        </div>
        <div class="company-name">陕西旭梦承星信息科技有限公司<span style="font-size:14px;margin-left:10px;">服务热线：029-81021331</span></div>
        <div class="set-info">
            <a-tooltip placement="bottomRight">
              <template slot="title">
                <div class="set" @click="visible=true" style="border-bottom:1px solid rgba(255,255,255,0.1)"><a-icon type="setting" />修改密码</div>
                <div class="set" @click="outLogin"><a-icon type="poweroff" />退出登录</div>
              </template>
              <div class="account-type"><a-icon type="user" /> 
                <span v-if="moduleId==3">{{$store.getters.getRoleName}}</span>
                <span v-else-if="moduleId==2">{{$store.state.busRole}}</span> 
                <span v-else>管理员</span> 
              </div>
            </a-tooltip>
            <!-- <div class="account-type">
              旭梦承星科技
            </div> -->
            <!-- 考场 -->
            <template v-if="moduleId==3">
              <div class="account-type">
                <span>有效期</span>
                {{$store.getters.getEndTime}}
              </div>
              <div class="account-type">
                <a-select  style="width: auto;max-width:320px" @change="changeSpot" v-model="$store.state.curSpotId">
                  <a-select-option :value="item.spotId" v-for="item in $store.state.examSpotList" :key="item.id">
                     {{item.spotName}}
                  </a-select-option>
                </a-select>
              </div>
            </template>
            <!-- 承星管理人员 -->
            <template v-if="moduleId==1">
              <div class="account-type">
                旭梦承星科技
              </div>
            </template> 
            <!-- 班车 -->
            <template v-if="moduleId==2">
              <div class="account-type">
                <span>有效期</span>
                {{$store.getters.getEndTime}}
              </div>
              <div class="account-type">
                {{$store.state.companyName}}
              </div>
            </template>
        </div>
        <!-- 修改密码 -->
        <a-modal
          title="修改密码"
          :visible="visible"
          @ok="handleOk"
          @cancel="visible=false"
        >
          <p class="flex">
            <label for="" class="type-name">原始密码</label>
            <a-input class="flex1" type="password" v-model="info.oldPwd" placeholder="旧密码" :maxLength="20"></a-input>
          </p>
          <p class="flex">
            <label for="" class="type-name">新密码</label>
            <a-input class="flex1" type="password" v-model="info.newPwd" placeholder="新密码" :maxLength="20"></a-input>
          </p>
        </a-modal>
    </div>
</template> 
<script>
export default {
  data(){
    return {
      curSpotId:this.$store.state.curSpotId,
      visible:false,
      info:{}
    }
  },
  watch:{},
  computed:{
    moduleId(){
      // moduleId:'1'//1：管理员 2：班车(bus) 3:收费(pay)
      if(/bus/.test(this.$route.fullPath)){
        return '2'
      }else if(/pay/.test(this.$route.fullPath)){
        return '3'
      }else{
        return '1'
      }
    }
  },
  methods:{
    handleOk(){
      let _this = this;
      if(!_this.info.oldPwd){
        this.$info({
          title:'请输入密码！',
          content:''
        })
        return
      }
      if(!_this.info.newPwd){
        this.$info({
          title:'请输入密码！',
          content:''
        })
        return
      }else{
        if(_this.info.newPwd.length<6){
          this.$info({
            title:'密码不得少于六位！',
            content:''
          })
          return
        }
      }
      this.$post(this.$url.修改密码, this.info).then(()=>{
        this.visible = false
        this.$success({
          title: '密码修改成功',
          // JSX support
          content: (
            <div>
              <p>点击确定，退出重新登录使用！</p>
            </div>
          ),
          onOk() {
            _this.outLogin()
          },
        });
      })
    },
    changeSpot(id){
      this.$store.commit("udpateCurSpotId",id)
    },
    outLogin(){
      this.$post(this.$url.退出登录, {}).then(()=>{
        this.$router.push("/")
      })
    }
  },
  mounted(){
    if(sessionStorage.getItem('companyId')==100)return
  }
}
</script> 
<style scoped>
.set{
  padding:8px 10px;
}
.set:hover{
  cursor:pointer;
}
.set .anticon{
  margin-right:8px;
}
    .logo{
  float:left;
  margin-left:20px;
  padding-top:13px;
}
.logo-pic{
  width:auto;
  height:24px;
  float:left;
}
.company-name{
  float:left;
  font-size:18px;
  color:#fff;
  line-height:50px;
  margin-left:20px;
}
.set-info{
  float:right;
  color:#fff;
  line-height:50px;
}
.account-type{
  float:right;
  margin-left:10px;  
  margin-right:20px;  
}
</style>