
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    companyId:sessionStorage.getItem('companyId'),
    companyName:'',
    busRole:sessionStorage.getItem('busRole'),
    curSpotId:'8',
    curSpotName:'8',
    billNo:'',//小票标题头
    examSpotList:[ ],
    load:'',
    menu: [],
  },
  mutations: {
    //更新公司id
    updateCompanyId(state,id){
      state.companyId = id
    },
    updateCompanyName(state, name) {
      state.companyName = name
    },
    updateBusRole(state,name){
      state.busRole = name
    },
    //更新当前选中考场
    udpateCurSpotId(state,id){
      state.curSpotId = id
      state.load = new Date().getTime()
    },
    updateSpotList(state,protype){
      state.examSpotList = protype
    },
    //更新时刻
    updateLoad(state,payload){
      state.load = payload
    },
    updatebillNo(state,payload){
      state.billNo = payload
    },
    //更新菜单
    updateMenu(state,payload){
      state.menu = payload
    }
  },
  getters: {
    //获取当前有效期
    getEndTime:(state)=>{
      for(var i=0;i<state.examSpotList.length;i++){
        if(state.examSpotList[i].spotId == state.curSpotId){
          return state.examSpotList[i].endTime
        }
      }
    },
    //获取当前角色
    getRoleName(state){
      for(var i=0;i<state.examSpotList.length;i++){
        if(state.examSpotList[i].spotId == state.curSpotId){
          return state.examSpotList[i].roleName
        }
      }
    }
  },
  actions: {
    //异步更新state数据
    updateList(context,payload){
      payload.then(res=>{
        if(res.data.list.length){
          let list=[]
          res.data.list.forEach(item => {
            list.push({
              spotName:item.shortName,
              spotId:String(item.id),
              endTime:item.expireDay,
              roleName:item.roleName,
              billTitle:item.billNo
            })
          });
          context.commit('updateLoad',new Date().getTime())
          context.commit('udpateCurSpotId',list[0].spotId)
          context.commit('updatebillNo',list[0].billTitle)
          context.commit('updateSpotList',list)
        }else{
          context.commit('updateLoad',new Date().getTime())
          Vue.prototype.$info({
            title: '警告',
            content: '目前暂无考场，无法进行任何操作！',
            onOk() {}
          });
          context.commit('udpateCurSpotId','')
          context.commit('updatebillNo','')
          context.commit('updateSpotList',[])
        }
      })
    },
    //异步更新菜单
    aUpdateMenu(context,payload){
      payload.then(menu=>{
        context.commit('updateMenu',menu.data.menuList)
      })
    } 
  },
  modules: {}
});
