import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Antd from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
Vue.use(Antd);

//引入网路请求
import '@/assets/js/http/http'
import "@/assets/js/http/url"
//引入提示
import "@/assets/js/tips"
import "@/assets/js/util"
import "@/assets/css/common.css"
//编辑器
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import moment from "moment"
import 'moment/locale/zh-cn';
moment.locale('zh-cn');
Vue.prototype.$moment = moment

Vue.prototype.tab = [
  
]
Vue.prototype.pageSize = 20

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
