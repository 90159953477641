import Vue from "vue";
import VueRouter from "vue-router";
import bodyLayout from "../../Layout/bodyLayout.vue";
import blankView from "../../Layout/blankView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: ()=> import( "../views/login.vue")
  },
  {
    path: "/body",
    name: "nav1",
    component: bodyLayout,
    children:[
      {
        path: "/manager",
        name: "信息管理",
        icon:'profile',
        component: blankView,
        children:[
          {
            hide:true,
            path: "/user",
            name: "管理员",
            component: ()=> import( "../views/manager/user.vue")
          },
          {
            path: "/carschool",
            name: "驾校管理",
            icon:'edit',
            hide:true,
            component: ()=> import( "../views/manager/car_school"),
          },
          {
            path: "/manager/log",
            name: "承星操作日志",
            icon:'edit',
            hide:true,
            component: ()=> import( "../views/manager/log"),
          },
        ]
      },
      {
        path: "/bus",
        name: "班车",
        icon:'car',
        redirect:"/bus/router",
        component: blankView,
        children:[
          {
            hide:true,
            path: "/bus/router",
            name: "线路管理",
            component: ()=> import( "../views/bus/router.vue")
          },
          {
            hide:true,
            path: "/bus/stu",
            name: "派车管理",
            component: ()=> import( "../views/bus/stu.vue")
          },
          {
            hide:true,
            path: "/bus/set_bus",
            name: "设置",
            icon:'setting',
            component: blankView,
            children:[
              {
                hide:true,
                path: "/bus/set_bus_user",
                name: "用户设置",
                component:()=> import( "../views/bus/set_bus_user")
              },
              {
                hide:true,
                path: "/bus/set_bus",
                name: "班车管理",
                component:()=> import( "../views/bus/set_bus")
              },
              {
                hide:true,
                path: "/bus/set_value",
                name: "参数设置",
                component:()=> import( "../views/bus/set_value")
              },
              {
                hide:true,
                path: "/bus/log",
                name: "班车操作日志",
                component:()=> import( "../views/bus/log")
              }
            ]
          },
        ],
      },
      {
        path: "/pay",
        name: "收费系统",
        icon:'money-collect',
        component: blankView,
        redirect:'/pay/addPay',
        children:[
          {
            hide:true,
            path: "/pay/addPay",
            name: "收费管理",
            component: ()=> import( "../views/pay/addPay.vue")
          },
          {
            hide:true,
            path: "/pay/stu",
            name: "查询统计",
            component: ()=> import( "../views/pay/stu.vue")
          },
          {
            hide:true,
            path: "/pay/rate",
            name: "合格率统计",
            component: ()=> import( "../views/pay/rate_static")
          },
          {
            hide:true,
            path: "/pay/import_stu",
            name: "数据导入导出",
            component: ()=> import( "../views/pay/import_stu")
          },
          {
            hide:true,
            path: "/pay/train_list",
            name: "练车名单",
            component: ()=> import( "../views/pay/train_list")
          },
          {
            hide:true,
            path: "/pay/set",
            name: "设置",
            icon:'setting',
            component: blankView,
            children:[
              {
                hide:true,
                path: "/pay/set/set_pay_user",
                name: "用户设置",
                component:()=> import( "../views/pay/set/set_pay_user")
              },
              {
                hide:true,
                path: "/pay/handle_starnd",
                name: "套餐设置",
                component:()=> import( "../views/pay/set/handle_starnd")
              },
              {
                hide:true,
                path: "/pay/set/car",
                name: "考车管理",
                component:()=> import( "../views/pay/set/car")
              },
              {
                hide:true,
                path: "/pay/set/score_model",
                name: "成绩导入模板",
                component:()=> import( "../views/pay/set/score_model")
              },
              {
                hide:true,
                path: "/pay/set/sale_per",
                name: "业务员",
                component:()=> import( "../views/pay/set/sale_per")
              },
              {
                hide:true,
                path: "/pay/set/invoice_title",
                name: "票据信息",
                component:()=> import( "../views/pay/set/invoice_title")
              },
              {
                hide:true,
                path: "/pay/set/log",
                name: "收费操作日志",
                component:()=> import( "../views/pay/set/log")
              }
            ]
          },
        ],
        
      },
    ]
  },
];
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
export default router;
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
