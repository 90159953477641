import Vue from 'vue'
import axios from 'axios'
import { Promise } from 'core-js'

//状态码
const codeArr = [
  '10003','10013'
]
//提示信息
const infoArray = [
  {code:'10013',title:'参数错误',info:'请检查提交的参数信息！'},
  {code:'10003',title:'错误提示',info:'您没有该操作权限，请联系管理员！'},
  
]
const isErrorCode = function(code){
  for(let i=0; i<codeArr.length;i++){
    if(codeArr[i] == code ){
      return true
    }
  }
  return false
}
const errorTitle = function(code){
  for(let i=0;i<infoArray.length;i++){
    if(infoArray[i].code == code)return infoArray[i].title
  }
  return null
}
const errorInfo = function(code){
  for(let i=0;i<infoArray.length;i++){
    if(infoArray[i].code==code)return infoArray[i].info
  }
  return null
}

//默认配置
axios.defaults.timeout = 60000
axios.defaults.responseType = 'json'
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

//请求拦截
axios.interceptors.request.use(function(config){
  config.headers.token = sessionStorage.getItem("token")
  return config;
},function(error){
  return Promise.reject(error)
})

//响应拦截
axios.interceptors.response.use(function(response){
  return response
},function(err){
  return Promise.reject(err)
})

//请求 -- post
Vue.prototype.$post = function(url,data){
  let promise = new Promise((resolve,reject)=>{
    axios.post(url,data).then(response=>{
      if(response.status == 200){
        if (response.data.status == "10000") {
          resolve(response.data);
        }else {
          if(response.data && isErrorCode(response.data.status)){
            let title = errorTitle(response.data.status)
            let info = errorInfo(response.data.status)
            this.$info({
              title: title,
              content: info,
              onOk() {

              }
            });
            //reject(response.data)
            return
          }else if(response.data && response.data.status == '10002'){
            //离线，强制登录
            this.$info({
                  title: "登录失效",
                  content: "账号已在其他设备登录，请重新登录！",
                  onOk() {}
                });
            if (this.$route.fullPath == "/") return;
            this.$router.push("/");
          }else{
            this.$info({
              title: '错误提示',
              content: response.data.msg,
              onOk() {
              }
            })
            reject(response.data)
          }
        }
      }
    }).catch(error=>{
      if (error == "Error: Request failed with status code 500") {
        this.$info({
          title: "请求失败",
          content: "后台服务异常"
        });
        return;
      } else {
        reject();
      }
    })
  })
  return promise
}
// 请求 -- get
Vue.prototype.$get = function(url,data){
  let promise = new Promise((resolve,reject)=>{
    axios
    .get(url,data)
    .then(response => {
      if( response.status == 200 ){
        let data = response.data
        resolve(response)
      }
    })
    .catch(error=>{
      reject()
    })
  })
}
// 请求 -- 下载，单独设置responsType
Vue.prototype.$download = function(url,data){
  let promise = new Promise((resolve,reject)=>{
    axios({
      method:'post',
      url:url,
      data:data,
      responseType:'arraybuffer'
    })
    .then(response=>{
      resolve(response.data)
    })
    .catch(error=>{

    })
  })
  return promise
}

//检查登录状态
let checkLogin = function(resp){
  if (resp.data.status == "10000") {
    return true;//在线
  }
  return false;//离线
}