import Vue from 'vue'

//导出excel
Vue.prototype.$downExe = (url,filter,name)=>{
  Vue.prototype.$download(url, filter).then(data => {
    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/octet-stream'}));
    // 生成一个a标签
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.download = name +".xls";   
    document.body.appendChild(link);
    link.click();
   });
}

//导出txt
Vue.prototype.$downTxt = (url,filter,name)=>{
  Vue.prototype.$download(url, filter).then(data => {
    let url = window.URL.createObjectURL(new Blob([data], {type: 'application/octet-stream'}));
    // 生成一个a标签
    let link = document.createElement("a");
    link.style.display = "none";
    link.href = url;
    link.download = name +".txt";   
    document.body.appendChild(link);
    link.click();
   });
}