<template>
    <div >
        <keep-alive exclude="hoststulist">
            <router-view></router-view>
        </keep-alive>
        
    </div>
</template>
<script>
export default {
    methods:{
        
    },
    mounted(){
        
    }
}
</script>
<style scoped>

</style>