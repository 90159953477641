import Vue from 'vue'

//只负责通知没有回调
//success  info  warning  error
const tips = function(title,content,type){
  this.$notification[type]({
    message: title,
    description:content,
    duration:3
  });
}
Vue.prototype.$tips = tips;