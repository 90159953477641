<template>
    <a-menu theme="dark"  @click="updateRoute" :open-keys="openKeys" @openChange="onOpenChange" v-model="selectedKeys"  mode="inline" :inlineCollapsed="true">
        <!-- menuList.children -->
        <template v-for="(item) in $store.state.menu">
            <a-sub-menu :key="item.uri" v-if="item.children && item.children.length">
                <span slot="title"><a-icon :type="item.icon" /><span>{{item.title}}</span></span>
                <template v-for="second in item.children" >
                  <a-sub-menu v-if="second.children && second.children.length" :key="second.uri" >
                    <span slot="title"><a-icon :type="second.icon" /><span>{{second.title}}</span></span>  
                    <a-menu-item v-for="thid in second.children" :key="thid.uri">
                      {{thid.title}}
                    </a-menu-item>
                  </a-sub-menu>  
                  <a-menu-item  :key="second.uri" v-else-if="second.hide">
                    {{second.title}}
                  </a-menu-item>   
                </template>
            </a-sub-menu>
            <a-menu-item v-else :key="item.uri" >
                {{item.title}}
            </a-menu-item>
        </template>
    </a-menu>
</template> 
<script>
    export default{
        data(){
            return{
                menuList:[],
                collapsed:true,
                openKeys:[],
                currentList:[],
                selectedKeys:[],
                rootSubmenuKeys: [],
            }
        },
        watch:{
            $route(to,from){
                this.updateKey(to.matched)
            }
        },
        computed:{
            // openKeys:{
            //     get(){
            //         console.log(this.$route)
            //         let arr = this.$route.matched
            //         let newArr = []
            //         arr.forEach((ar,index)=>{
            //             if(index!=0 || index!=arr.length-1){
            //                 newArr.push(ar.path)
            //             }
            //         })
            //         return newArr
            //     },
            //     set(){

            //     }
                
            // },
            // selectedKeys:{
            //     get(){
            //         return [ this.$route.matched[this.$route.matched.length-1].path ]
            //     },
            //     set(){

            //     }
            // }
        },
        methods:{
            toggleCollapsed() {
                this.collapsed = !this.collapsed;
            },
            updateRoute(e){
                this.$router.push({ path:e.key })
            },
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1);
                if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                    this.openKeys = openKeys;
                } else {
                    this.openKeys = latestOpenKey ? [latestOpenKey] : [];
                }
            },
            updateKey(arr){
                let newArr = []
                arr.forEach((ar,index)=>{
                    if(index!=0 || index!=arr.length-1){
                        newArr.push(ar.path)
                    }
                })
                this.openKeys = newArr
                this.selectedKeys =[ arr[arr.length-1].path ]
            }
        },
        mounted(){
            this.updateKey(this.$route.matched)
            //this.$store.dispatch('aUpdateMenu',this.$post(this.$url.获取菜单, {}))
            this.$post(this.$url.获取菜单, {}).then(res=>{
                this.$store.commit('updateMenu',res.data.menuList)
                if(sessionStorage.getItem('companyId')==100){
                    this.$router.push('/carschool')
                    return
                }
                if(res.data.menuList[0].menuId==100){
                    this.$store.dispatch('updateList',this.$post(this.$url.用户所有考场, {}))
                    .then(res=>{})
                    .catch(err=>{})
                    if(this.$route.fullPath=='/body')this.$router.push('/pay')
                }else{
                   if(this.$route.fullPath=='/body')this.$router.push('/bus') 
                   this.$store.commit('updateLoad',new Date().getTime())
                }
                
            })
        }
    }
</script>   
<style scoped>
    
</style>